<template>
  <section>
    <section class="list-single-container" v-if="recordMap.length">
      <a-row class="list-border list-item m-t-18 list-item-image" v-for="(item,key) in recordMap" :key="key">
        <a-row class="image-wrap">
          <img :src="item.avatar" alt />
        </a-row>
        <a-row class="action-btn-group">
          <a-row>
            <router-link :to="{path:'/pcms/labour/person/details',query:{id:item.personnelId}}"><a-button size="small" class="m-b-8">查看</a-button></router-link>
            <a-button size="small" class="m-b-8">离场凭证</a-button>
            <a-button size="small" class="m-b-8">上传凭证</a-button>
            <!-- <router-link class="m-b-8" to>
              <a-button size="small">查看</a-button>
            </router-link> -->
            <a-popconfirm @confirm="deleteItem(item.id)" title="确定删除该离场记录?"><a-button size="small">删除</a-button></a-popconfirm>
           
          </a-row>
        </a-row>
        <p>离场记录（已退场）</p>
        <a-row class="m-b-10">
          <a-col :span="8">
            <span class="key">所属单位：</span>
            <span class="val">{{item.companyName}}</span>
          </a-col>
          <a-col :span="8">
            <span class="key">姓名：</span>
            <span class="val">{{item.personnelName}}</span>
          </a-col>
        </a-row>
        <a-row class="m-b-10">
          <a-col :span="8">
            <span class="key">单位类型：</span>
            <span class="val">{{item.companyType}}</span>
          </a-col>
          <a-col :span="8">
            <span class="key">登记时间：</span>
            <span class="val">{{item.inDate}}</span>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <span class="key">人员职务：</span>
            <span class="val">{{item.jobName}}</span>
          </a-col>
          <a-col :span="8">
            <span class="key">退场时间：</span>
            <span class="val">{{item.outDate}}</span>
          </a-col>
        </a-row>
      </a-row>
    </section>
    <empty v-else :height="430" />
    <section class="pagination-container">
            <a-pagination size="default" :current="pageNum" :total="total" hide-on-single-page 
              :show-total="(total) => `共${total}条`"
            :showQuickJumper="true"
		    :showSizeChanger="false"
            @change="changePageNum" />
        </section>
  </section>
</template>

<script>
import empty from '@/components/empty'
// import previewImage from '@/components/previewImage'
export default {
  data() {
    return {
        pageNum:1,
        total:0,
        recordMap: [],
        baseImage: require('@/assets/image/personnel.png')
    };
  },
   components:{empty},
  props:["personnelId"],
  computed: {
    pid() {
      return this.$route.query.id || this.personnelId;
    }
  },
  created() {
      this.queryPersonnelExitLog()
  },
  methods: {
    queryPersonnelExitLog() {
      let params = {
        id: this.pid,
        current:this.pageNum
      };
      this.$api.queryPersonnelExitLog(params).then(res => {
        if (res.code === 200) {
          this.recordMap = (res.data && res.data.records) || [];
          this.total = (res.data && res.data.total) || 0
          this.recordMap.forEach(item =>{
            item.avatar = item.avatar ? item.avatar.includes('http') ? item.avatar : this.$store.state.picServer+item.avatar : this.baseImage
          })
        }
      });
    },
    changePageNum(p){
      this.pageNum = p
      this.queryPersonnelExitLog()
    },
    deleteItem(id){
       this.$api.deletePersonnel({id}).then(res =>{
            if(res.code === 200){
                this.queryPersonnelExitLog()
            }
        })
    },
  }
};
</script>